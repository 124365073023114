<template>
    <div class="animated fadeIn">
        <b-card no-body>
            <b-card-header>
                <i class="icon-note"></i> Update Shipping SAP
                <div class="card-header-actions">
                    <a class="card-header-action" href="shipping-cost-sap" rel="noreferrer noopener">
                        <small class="text-muted">Back</small>
                    </a>
                </div>
            </b-card-header>
            <b-card-body>
                <div class="loading" v-if="isLoading == true">
                    <div class="sk-three-bounce">
                        <div class="sk-child sk-bounce1"></div>
                        <div class="sk-child sk-bounce2"></div>
                        <div class="sk-child sk-bounce3"></div>
                    </div>
                </div>
                <b-row>
                    <b-col sm="12">
                        <!--<h6>Simple Form</h6>-->
                        <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
                            <b-form-group id="origin"
                                      label="Origin Shipping"
                                      label-for="origin">
                              <b-form-select id="origin"
                                v-model="form.origin"
                                :plain="true"
                                aria-describedby="originFeedback"
                                :options="originOption"
                              />
                              <b-form-invalid-feedback id="originFeedback">
                                <span v-if="!$v.form.origin.required">- Origin Shipping tidak boleh kosong.</span>
                              </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group id="shipping_type"
                                            label="Shipping Type"
                                            label-for="shipping_type">
                                <b-form-input id="shipping_type"
                                            type="text"
                                            v-model.lazy.trim="$v.form.shipping_type.$model"
                                            :state="chkState('shipping_type')"
                                            aria-describedby="shipping_typeFeedback"
                                            placeholder="Please Enter Shipping Type"
                                            autofocus />
                                <b-form-invalid-feedback id="shippingFeedback">
                                  <span v-if="!$v.form.shipping_type.required">- Shipping Type tidak boleh kosong! </span>
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group id="Province"
                                          label="Province"
                                          label-for="province">
                              <b-form-input id="province"
                                          type="text"
                                          v-model.lazy.trim="$v.form.province.$model"
                                          :state="chkState('province')"
                                          aria-describedby="provinceFeedback"
                                          list="my-list-id"
                                          autocomplete="off"
                                          placeholder="Pilih Provinsi"
                                          autofocus
                                          @keyup="checkProvince"
                                          @change="getCity(form.province)"
                              />
                                    <datalist id="my-list-id">
                                      <option
                                        v-for="(item, key) in provinceOption"
                                        :key="key"
                                      >
                                        {{ item.text }}
                                      </option>
                                    </datalist>
                              <b-form-invalid-feedback id="provinceFeedback" >
                                <span v-if="!$v.form.province.required">- Provinsi tidak boleh kosong!</span><br>
                              </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group id="city"
                                          label="City"
                                          label-for="city">
                              <b-form-input id="city"
                                          type="text"
                                          v-model.lazy.trim="$v.form.city.$model"
                                          :state="chkState('city')"
                                          aria-describedby="cityFeedback"
                                          list="city-list"
                                          autocomplete="off"
                                          placeholder="Pilih Kota"
                                          autofocus
                                          @keyup="checkCity"
                                          @change="getDistrict(form.city)"
                              />
                                    <datalist id="city-list">
                                      <option
                                        v-for="(item, key) in cityOption"
                                        :key="key"
                                      >
                                        {{ item.text }}
                                      </option>
                                    </datalist>
                              <b-form-invalid-feedback id="cityFeedback" >
                                <span v-if="!$v.form.city.required">- Kota tidak boleh kosong!</span><br>
                              </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group id="district"
                                          label="District"
                                          label-for="district">
                              <b-form-input id="district"
                                          type="text"
                                          v-model.lazy.trim="$v.form.district.$model"
                                          :state="chkState('district')"
                                          aria-describedby="districtFeedback"
                                          list="district-list"
                                          autocomplete="off"
                                          placeholder="Pilih Kecamatan"
                                          autofocus
                                          @keyup="checkDistrict"
                                          @change="getVillage"
                              />
                                    <datalist id="district-list">
                                      <option
                                        v-for="(item, key) in districtOption"
                                        :key="key"
                                      >
                                        {{ item.text }}
                                      </option>
                                    </datalist>
                              <b-form-invalid-feedback id="districtFeedback" >
                                <span v-if="!$v.form.district.required">- Kecamatan tidak boleh kosong!</span><br>
                              </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group id="village"
                                          label="Village"
                                          label-for="village">
                              <b-form-input id="village"
                                          type="text"
                                          v-model.lazy.trim="$v.form.village.$model"
                                          :state="chkState('village')"
                                          aria-describedby="villageFeedback"
                                          list="village-list"
                                          autocomplete="off"
                                          placeholder="Pilih Kelurahan"
                                          autofocus
                                          @change="fillPostalCode"
                              />
                                    <datalist id="village-list">
                                      <option
                                        v-for="(item, key) in villageOption"
                                        :key="key"
                                      >
                                        {{ item.text }}
                                      </option>
                                    </datalist>
                              <b-form-invalid-feedback id="villageFeedback" >
                                <span v-if="!$v.form.village.required">- Kelurahan tidak boleh kosong!</span><br>
                              </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group id="postal_code"
                                          label="Postal Code"
                                          label-for="postal_code">
                              <b-form-input id="postal_code"
                                          type="text"
                                          v-model.lazy.trim="$v.form.postal_code.$model"
                                          :state="chkState('postal_code')"
                                          aria-describedby="postalCodeFeedback"
                                          placeholder="Masukan Kode Pos"
                                          autocomplete='given-name'
                                          autofocus />
                              <b-form-invalid-feedback id="postalCodeFeedback" >
                                <span v-if="!$v.form.postal_code.required">- Kode Pos tidak boleh kosong!</span><br>
                                <span v-if="!$v.form.postal_code.maxLength">- Kode Pos harus bejumlah 5 digit!</span><br>
                                <span v-if="!$v.form.postal_code.minLength">- Kode Pos harus bejumlah 5 digit!</span><br>
                              </b-form-invalid-feedback>
                          </b-form-group>
                            <b-form-group id="fee"
                                          label="Fee (Rp)"
                                          label-for="fee">
                              <b-form-input id="fee"
                                          type="number"
                                          v-model.lazy.trim="$v.form.fee.$model"
                                          :state="chkState('fee')"
                                          aria-describedby="feeFeedback"
                                          placeholder="0"
                                          autocomplete='given-name'
                                          autofocus />
                              <b-form-invalid-feedback id="feeFeedback" >
                                <span v-if="!$v.form.fee.required">- Fee tidak boleh kosong!</span><br>
                                <span v-if="!$v.form.fee.numeric">- Fee harus berupa angka valid.</span>
                              </b-form-invalid-feedback>
                          </b-form-group>
                            <b-button type="submit" variant="primary">
                                Submit
                            </b-button>
                        </b-form>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { required, numeric, maxLength, minLength } from "vuelidate/lib/validators"

export default {
  name: "update-sap",
  data() {
    return {
        form: {
            shipping_type: '',
            fee: '',
            origin: '',
            province: '',
            district: '',
            city: '',
            village: '',
            postal_code: '',
        },
        originOption: [],
        provinceOption: [],
        cityOption: [],
        districtOption: [],
        villageOption: [],
        errors: {
            message: [],
            code: '',
            status: [],
            headers: [],
            form: {
                title: [],
                content: [],
                image: [],
                category: [],
                slug: []
            },
        },
        isLoading: false
    }
  },
  async created() {
    await this.$http.get(`shipping-cost/${this.$route.params.id}`).then((res) => {
      let data = res.data.data
      this.form.origin = data.origin
      this.form.shipping_type = data.shipping_type,
      this.form.city = data.city,
      this.form.district = data.district
      this.form.village = data.village
      this.form.postal_code = data.postal_code
      this.form.province = data.province
      this.form.fee = data.fee
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
    })

    await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/origin-shipping-command`).then((res) => {
      this.originOption = res.data.data
    })
    await axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/province-command').then((res) => {
      this.provinceOption = res.data.data
    })
    await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/city-command`).then((res) => {
      this.cityOption = res.data.data
    })
    await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/district-command`).then((res) => {
      this.districtOption = res.data.data
    })
    let city = this.cityOption.filter((v) => {
      return v.text === this.form.city
    })
    let district = this.districtOption.filter((v) => {
      return v.text === this.form.district
    })
    await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/village-command?city=${city[0].value}&district=${district[0].value}`).then((res) => {
      this.villageOption = res.data.data
    })
  },
    computed: {
        formStr() { return JSON.stringify(this.form, null, 4) },
        isValid() { return !this.$v.form.$anyError },
        isDirty() { return this.$v.form.$anyDirty },
        invCheck() { return 'You must accept before submitting' }
    },
    mixins: [validationMixin],
    validations: {
        form: {
            shipping_type: {
                required,
            },
            fee: {
                required,
                numeric
            },
            origin: {
              required
            },
            province: {
              required
            },
            city: {
              required
            },
            district: {
              required
            },
            village: {
              required
            },
            postal_code: {
              required,
              maxLength: maxLength(5),
              minLength: minLength(5),
            }
        }
    },
    methods: {
      async checkProvince(){
        this.isLoading = true
        if (this.form.province === '') {
          await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/city-command`).then((res) => {
            this.cityOption = res.data.data
            this.form.city = ''
            this.form.district = ''
            this.form.village = ''
            this.form.postal_code = ''
            this.isLoading = false
          })
        } else {
          this.form.city = ''
          this.form.district = ''
          this.form.village = ''
          this.form.postal_code = ''
          this.isLoading = false
        }
      },
      async checkCity(){
        this.isLoading = true
        if (this.form.city === '') {
          await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/district-command`).then((res) => {
            this.districtOption = res.data.data
            this.form.district = ''
            this.form.village = ''
            this.form.postal_code = ''
            this.isLoading = false
          })
        } else {
          this.form.district = ''
          this.form.village = ''
          this.form.postal_code = ''
          this.isLoading = false
        }
      },
      async checkDistrict(){
        this.isLoading = true
        if (this.form.district === '') {
          await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/village-command`).then((res) => {
            this.villageOption = res.data.data
            this.form.village = ''
            this.form.postal_code = ''
            this.isLoading = false
          })
        } else {
          this.form.village = ''
          this.form.postal_code = ''
          this.isLoading = false
        }
      },
      async getCity(val){
        this.isLoading = true
        let filtered = this.provinceOption.filter((v) => {
          return v.text === val
        })
        if (filtered.length) {
          await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/city-command?province=${filtered[0].value}`).then((res) => {
            this.cityOption = res.data.data
            this.isLoading = false
          })
        }
        this.isLoading = false
      },
      async getDistrict(val){
        this.isLoading = true
        let filtered = this.cityOption.filter((v) => {
          return v.text === val
        })
        if (filtered.length) {
          await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/district-command?city=${filtered[0].value}`).then((res) => {
            this.districtOption = res.data.data
            this.isLoading = false
          })
        }
        this.isLoading = false
      },
      async getVillage(){
        this.isLoading = true
        if (this.form.city !== '' && this.form.district !== '') {
          let city = this.cityOption.filter((v) => {
            return v.text === this.form.city
          })
          let district = this.districtOption.filter((v) => {
            return v.text === this.form.district
          })
          await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/village-command?city=${city[0].value}&district=${district[0].value}`).then((res) => {
            this.villageOption = res.data.data
            this.isLoading = false
          })
        }
        this.isLoading = false
      },
      async fillPostalCode() {
        this.isLoading = true
        if (this.form.city !== '' && this.form.district !== '' && this.form.village) {
          let city = this.cityOption.filter((v) => {
            return v.text === this.form.city
          })
          let district = this.districtOption.filter((v) => {
            return v.text === this.form.district
          })
          await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/postal-code-command?city=${city[0].value}&district=${district[0].value}&village=${this.form.village}`).then((res) => {
            this.form.postal_code = res.data.data
            this.isLoading = false
          })
        }
        this.isLoading = false
      },
        onSubmit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
              let province = this.provinceOption.filter((v) => {
                return v.text === this.form.province
              })
              let city = this.cityOption.filter((v) => {
                return v.text === this.form.city
              })
              let district = this.districtOption.filter((v) => {
                return v.text === this.form.district
              })
              let village = this.villageOption.filter((v) => {
                return v.text === this.form.village
              })
              let data = {
                origin: this.form.origin,
                shipping_type: this.form.shipping_type,
                fee: this.form.fee,
                city: city[0].value,
                district: district[0].value,
                village: village[0].value,
                postal_code: this.form.postal_code,
                province: province[0].value,
                method: 'SAP',
                _method: 'PUT'
              }

              this.$http.post(`shipping-cost/${this.$route.params.id}`, data)
              .then(() => {
                  this.isLoading = false
                  this.$router.push("/shipping-cost-sap");
                  this.$toasted.success('Shipping successfully updated!')
              }).catch((error) => {
                  if (error.response) {
                  this.isLoading = false
                  if(error.response.data.meta.code == 400){
                    this.$swal.fire(
                      'Failed!',
                      error.response.data.meta.message,
                      'error'
                    )
                  }else if(error.response.data.meta.code == 422){
                    this.$swal.fire(
                      'Failed!',
                      error.response.data.meta.message,
                      'error'
                    )
                  }
                }
              })
            }
        },
        chkState(val) {
            const field = this.$v.form[val]
            return !field.$dirty || !field.$invalid
        },
        findFirstError(component = this) {
            if (component.state === false) {
                if (component.$refs.input) {
                component.$refs.input.focus()
                return true
                }
                if (component.$refs.check) {
                component.$refs.check.focus()
                return true
                }
            }
            let focused = false
            component.$children.some((child) => {
                focused = this.findFirstError(child)
                return focused
            })

            return focused
        },
        validate() {
            this.$v.$touch()
            this.$nextTick(() => this.findFirstError())
            return this.isValid
        }
    }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.preview {
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  width: 50%;
  height: 50%;
}
.image-preview {
  padding: 20px;
}

.invalid-feedback {
    font-size: 100% !important;
}
.notValidate {
  border: #f86c6b 1px solid;
}
.validate {
  border: green 1px solid;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}

</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
